<template>
  <div>
    <v-card elevation="0" v-if="show">
      <v-card-text>
        <v-text-field
          :label="$t('labels.title')"
          outlined
          hide-details
          v-model="distributor.title"
        />
      </v-card-text>
    </v-card>
    <br />

    <lms-btn-holder>
      <v-btn large depressed color="primary" @click="save">
        {{ $t('labels.save') }}
      </v-btn>
      <v-btn large depressed @click="back">
        {{ $t('labels.cancel') }}
      </v-btn>
    </lms-btn-holder>
  </div>
</template>

<script lang="js">
import validatorRules from '../../../../helpers/validator_rules'

export default {
	data: () => ({
		refresh: 0,
    tab: 0,
    maxTitleSize: 110,
    edit: false
	}),
	computed: {
    show(){
      if(this.edit){
        return (typeof this.distributor.title != 'undefined')
      } else {
        return true
      }
    },
    requiredRule() {
      return validatorRules.required(this.$t("rules.required"))
    },
    systemLangs() {
      return this.$store.getters['system/langs']
    },
    distributor(){
      return this.$store.state.setting.distributor.view
    }
  },
  created(){
    if(this.$route.name == 'settings-distributor-edit'){
      this.edit = true
      this.get()
    } else {
      this.$store.commit('system/loading', false);
    }
  },
  methods: {
    async get() {
      this.$store.dispatch('setting/getDistributor',{
        countryID: this.$route.params.countryid,
        distributorID: this.$route.params.id
      })
    },
    async save() {
      const countryID = this.$route.params.countryid
			await this.$store.dispatch(
				'setting/saveDistributor',
        countryID
			);
			this.back()
    },
		back(){
			this.$router.push({
        name:'settings-country-list'
      });
		}
  }
}
</script>
