<template>
  <div>
    <f-page-header :title="title" :prevLinks="prevLinks" />

    <f-container topBottom>
      <f-form />
    </f-container>
  </div>
</template>

<script lang="js">
import FForm from '../../../../components/views/settings/data/DistributorForm.vue'

export default {
	components: {
		FForm
	},
	data: () => ({
		editing: false
	}),
	computed: {
		prevLinks() {
      return [
        {
          title: this.$t("labels.settings"),
          route: {
            name: "settings"
          }
        },
        {
          title: this.$t("labels.countries"),
          route: {
            name: "settings-country-list"
          }
        }
      ];
    },
    title() {
      return this.$t("labels.addDistribuitor");
    },
	},
	created(){
		if(this.$route.name == 'settings-training-type-edit') {
			this.editing = true
		}
	}
}
</script>
